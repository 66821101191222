import { Injectable } from '@angular/core';
import { IGetEmployeeResponse } from '@app/shared/models/api/employees/get-employee';
import { IProfileResponse } from '@app/shared/models/api/profile/profile-response';
import { UserService } from '@app/shared/services/user/user.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { loginFailure } from '@storeModule/auth/auth.actions';
import { IStoreState } from '@storeModule/store-state';
import { getLoggedUser, saveUser } from '@storeModule/user/user.actions';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

@Injectable()
export class UserEffects {
  getLoggedUser$ = createEffect(() => this.actions$
    .pipe(
      ofType(getLoggedUser),
      switchMap(() => this.userService.getUser()),
      map((newUser: IGetEmployeeResponse | IProfileResponse) => saveUser({user: newUser})),
      catchError(() => of(loginFailure()))
    ));

  constructor(
    private store: Store<IStoreState>,
    private userService: UserService,
    private actions$: Actions
  ) {
  }
}
