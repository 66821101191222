import { addSmallNumbers, subtractionOfSmallNumbers } from '@app/shared/methods/numbers/numbers';
import { Action, createReducer, on } from '@ngrx/store';
import { IPaymentState } from '@storeModule/payment/payment-state.interface';
import {
  calculateFee,
  calculateFundsLeftAfterTransfer,
  calculateTotal,
  decreaseWithdraw,
  increaseWithdraw,
  saveAvailableFunds,
  saveFee,
  setWithdraw
} from '@storeModule/payment/payment.actions';
import { paymentState } from '@storeModule/payment/payment.state';

export function reducer(state: IPaymentState | undefined, action: Action) {
  return paymentReducer(state, action);
}

const paymentReducer = createReducer(
  paymentState,
  on(increaseWithdraw, (state: IPaymentState) => {
    if (!(state.withdraw.value + state.withdraw.step > state.funds.value)) {
      return {
        ...state,
        withdraw: {
          ...state.withdraw,
          value: state.withdraw.value + state.withdraw.step
        }
      };
    }

    return state;

  }),
  on(decreaseWithdraw, (state: IPaymentState) => {
    if (state.withdraw.value - state.withdraw.step >= state.withdraw.min) {
      return {
        ...state,
        withdraw: {
          ...state.withdraw,
          value: state.withdraw.value - state.withdraw.step
        }
      };
    }

    return state;
  }),

  on(setWithdraw, (state: IPaymentState, props: { withdraw: number }) => ({
    ...state,
    withdraw: {
      ...state.withdraw,
      value: props.withdraw
    }
  })),
  on(calculateFee, (state: IPaymentState) => {
    return {
      ...state,
      status: {
        ...state.status,
        feeLoading: true
      }
    };
  }),
  on(saveFee, (state: IPaymentState, props: { fee: number }) => {
    return {
      ...state,
      withdraw: {
        ...state.withdraw,
        fee: props.fee
      },
      status: {
        ...state.status,
        feeLoading: false
      }
    };
  }),
  on(saveAvailableFunds, (state: IPaymentState, props: { funds: number }) => ({
    ...state,
    funds: {...state.funds, value: props.funds},
    status: {...state.status, withdrawalDisabled: props.funds < state.withdraw.min}
  })),
  on(calculateTotal, (state: IPaymentState) => {
    return {
      ...state,
      withdraw: {
        ...state.withdraw,
        total: addSmallNumbers(state.withdraw.value, state.withdraw.fee)
      }
    };
  }),
  on(calculateFundsLeftAfterTransfer, (state: IPaymentState) => {
    return {
      ...state,
      funds: {
        ...state.funds,
        leftAfterWithdraw: subtractionOfSmallNumbers(state.funds.value, state.withdraw.total)
      }
    };
  })
);
