import { ScreenResolution } from '@app/shared/services/screen-resolution-listener.service/screen-resolution.enum';
import { Action, createReducer, on } from '@ngrx/store';
import { IScreenState } from '@storeModule/screen/screen-state.interface';
import { saveScreenResolution } from '@storeModule/screen/screen.actions';
import { screenState } from '@storeModule/screen/screen.state';

export function reducer(state: IScreenState | undefined, action: Action) {
  return screenReducer(state, action);
}

export const screenReducer = createReducer(
  screenState,
  on(saveScreenResolution, (state: IScreenState, props: { screenResolution: ScreenResolution }) => ({
    ...state,
    resolution: props.screenResolution
  }))
);
