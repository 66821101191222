import { Injectable } from '@angular/core';
import { EmployeesApiService } from '@app/core/api/employees/employees-api.service';
import { IGetFeesResponse } from '@app/shared/models/api/employees/get-fees';
import { IGetFundsResponse } from '@app/shared/models/api/employees/get-funds';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import {
  calculateFee,
  calculateFundsLeftAfterTransfer,
  calculateTotal,
  createWithdraw,
  decreaseWithdraw,
  getAvailableFunds,
  increaseWithdraw,
  saveAvailableFunds,
  saveFee,
  setWithdraw
} from '@storeModule/payment/payment.actions';
import { selectAvailableFunds, selectFee, selectWithdraw } from '@storeModule/payment/payment.selectors';
import { IStoreState } from '@storeModule/store-state';
import { debounceTime, map, switchMap, take, withLatestFrom } from 'rxjs/operators';

@Injectable()
export class PaymentEffects {
  getAvailableFunds$ = createEffect(() => this.actions$
    .pipe(
      ofType(getAvailableFunds),
      switchMap(() => this.employeesApiService.getFunds()),
      map((response: IGetFundsResponse) => response.availableFunds),
      map(((funds: number) => saveAvailableFunds({funds})))
    ));

  calculateFee$ = createEffect(() => this.actions$
    .pipe(
      ofType(calculateFee),
      withLatestFrom(this.store.select(selectWithdraw)),
      debounceTime(500),
      switchMap(([action, withdraw]: [Action, number]) => this.employeesApiService.getFees(withdraw)),
      map((response: IGetFeesResponse) => response.fee),
      map((fee: number) => saveFee({fee}))
    ));

  saveFee$ = createEffect(() => this.actions$
    .pipe(
      ofType(saveFee),
      withLatestFrom(this.store.select(selectWithdraw).pipe(take(1))),
      map(() => calculateTotal())
    ));

  calculateTotal$ = createEffect(() => this.actions$
    .pipe(
      ofType(calculateTotal),
      map(() => calculateFundsLeftAfterTransfer())
    ));

  increaseWithdraw$ = createEffect(() => this.actions$
    .pipe(
      ofType(increaseWithdraw),
      map(() => calculateFee())
    ));

  decreaseWithdraw$ = createEffect(() => this.actions$
    .pipe(
      ofType(decreaseWithdraw),
      map(() => calculateFee())
    ));

  setWithdraw$ = createEffect(() => this.actions$
    .pipe(
      ofType(setWithdraw),
      map(() => calculateFee())
    ));

  createWithdraw$ = createEffect(() => this.actions$
    .pipe(
      ofType(createWithdraw),
      withLatestFrom(
        this.store.select(selectWithdraw),
        this.store.select(selectFee)
      ),
      switchMap(([action, withdrawalAmount, fee]: [null, number, number]) => this.employeesApiService.createWithdrawal({
        withdrawalAmount,
        fee
      })
        .pipe(
          withLatestFrom(this.store.select(selectAvailableFunds)),
          switchMap(([response, funds]) => [
            getAvailableFunds(),
            setWithdraw({
              withdraw: (funds - withdrawalAmount - fee) > withdrawalAmount ? withdrawalAmount : (funds - withdrawalAmount - fee)
            })
          ])
        ))
    ));

  constructor(
    private store: Store<IStoreState>,
    private actions$: Actions,
    private employeesApiService: EmployeesApiService
  ) {
  }
}
