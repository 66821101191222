import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { PredefinedDateFormats } from '@app/shared/services/date/date-formats.enum';

/**
 * @Documentation https://angular.io/api/common/DatePipe#custom-format-options
 */

@Injectable()
export class DateService {
  constructor(private datePipe: DatePipe) {
  }

  format(date: Date, format: string | PredefinedDateFormats): string {
    return this.datePipe.transform(date, format);
  }

  stringToDate(date: string, format: string, delimiter: string): Date {
    const formatLowerCase = format.toLowerCase();
    const formatItems = formatLowerCase.split(delimiter);
    const dateItems = date.split(delimiter);
    const monthIndex = formatItems.indexOf('mm');
    const dayIndex = formatItems.indexOf('dd');
    const yearIndex = formatItems.indexOf('yyyy');
    let month = parseInt(dateItems[monthIndex]);
    month -= 1;
    return new Date(+dateItems[yearIndex], month, +dateItems[dayIndex]);
  }

  getMonthRange(date: Date): { from: Date, to: Date } {
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    return {
      from: firstDay,
      to: lastDay
    };
  }

  getMonthDiff(date1: Date, date2: Date) {
    let months;
    months = (date2.getFullYear() - date1.getFullYear()) * 12;
    months -= date1.getMonth() + 1;
    months += date2.getMonth();
    return Math.abs(months);
  }

  isDateValid(date: Date): boolean {
    return date instanceof Date && !isNaN(+date);
  }
}
