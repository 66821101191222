import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/shared/services/auth/auth.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import {
  login,
  loginFailure,
  loginSuccess,
  logout,
  refreshToken,
  refreshTokenFailure,
  refreshTokenSuccess,
} from '@storeModule/auth/auth.actions';
import { IStoreState } from '@storeModule/store-state';
import { getLoggedUser, removeUser } from '@storeModule/user/user.actions';
import { of } from 'rxjs';
import { catchError, concatMap, map, tap } from 'rxjs/operators';

@Injectable()
export class AuthEffects {
  login$ = createEffect(() => this.actions$
    .pipe(
      ofType(login),
      tap(() => this.authService.initLoginFlow())
    ), {dispatch: false});

  loginSuccess$ = createEffect(() => this.actions$
    .pipe(
      ofType(loginSuccess),
      map((loginSuccessAction) => {
        localStorage.setItem('token', loginSuccessAction.token);
        if (!loginSuccessAction.hasOwnProperty('redirect') || loginSuccessAction.redirect === true) {
          this.router.navigate(['']);
        }

        return getLoggedUser();
      }),
    ));

  logout$ = createEffect(() => this.actions$
    .pipe(
      ofType(logout),
      tap(() => {
        localStorage.removeItem('token');
        this.authService.initLogoutFlow();
      })), {dispatch: false});

  loginFailure$ = createEffect(() => this.actions$
    .pipe(
      ofType(loginFailure),
      map(() => {
        localStorage.removeItem('token');
        this.router.navigate(['welcome']);
        return removeUser();
      })
    ));

  refreshToken$ = createEffect(() => this.actions$
    .pipe(
      ofType(refreshToken),
      concatMap(() => this.authService.refreshToken()
        .pipe(
          map(token => refreshTokenSuccess({token})),
          catchError(() => of(refreshTokenFailure()))
        ))
    ));

  refreshTokenFailure$ = createEffect(() => this.actions$
    .pipe(
      ofType(refreshTokenFailure),
      map(() => loginFailure())
    ));

  constructor(
    private store: Store<IStoreState>,
    private actions$: Actions,
    private router: Router,
    private authService: AuthService
  ) {
  }
}
