import { createSelector } from '@ngrx/store';
import { IPaymentState } from '@storeModule/payment/payment-state.interface';
import { IStoreState } from '@storeModule/store-state';

export const selectPaymentState = (state: IStoreState) => state.payment;

export const selectWithdraw = createSelector(
  selectPaymentState,
  (state: IPaymentState) => state.withdraw.value
);

export const selectFee = createSelector(
  selectPaymentState,
  (state: IPaymentState) => state.withdraw.fee
);

export const selectTotalToWithdraw = createSelector(
  selectPaymentState,
  (state: IPaymentState) => state.withdraw.total
);

export const selectAvailableFunds = createSelector(
  selectPaymentState,
  (state: IPaymentState) => state.funds.value
);

