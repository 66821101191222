import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogConfig, MatDialogModule, MatIconRegistry, MatProgressBarModule } from '@angular/material';
import { DomSanitizer, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { HammerConfig } from '@app/core/hammer.config';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    MatDialogModule,
    MatProgressBarModule
  ],
  exports: [MatDialogModule, MatProgressBarModule]
})
export class MaterialCoreModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: MaterialCoreModule,
      providers: [
        MatIconRegistry,
        {provide: HAMMER_GESTURE_CONFIG, useClass: HammerConfig},
        {
          provide: MAT_DIALOG_DEFAULT_OPTIONS,
          useValue: {
            ...new MatDialogConfig(),
            backdropClass: 'dialog-backdrop',
            panelClass: 'dialog-card'
          } as MatDialogConfig
        },
      ]
    };
  }

  constructor(private icons: MatIconRegistry, private domSanitizer: DomSanitizer) {
    icons
      .setDefaultFontSetClass('material-icons')
      .registerFontClassAlias('material-normal', 'material-icons')
      .registerFontClassAlias('material-outlined', 'material-icons-outlined')
      .registerFontClassAlias('material-round', 'material-icons-round')
      .registerFontClassAlias('material-sharp', 'material-icons-sharp')
      .registerFontClassAlias('material-two-tone', 'material-icons-two-tone');

    icons
      .addSvgIcon('mw-active', this.trustSvgUrl('active.svg'))
      .addSvgIcon('mw-company', this.trustSvgUrl('company.svg'))
      .addSvgIcon('mw-contacts', this.trustSvgUrl('contacts.svg'))
      .addSvgIcon('mw-cu', this.trustSvgUrl('cu.svg'))
      .addSvgIcon('mw-download', this.trustSvgUrl('download.svg'))
      .addSvgIcon('mw-edit', this.trustSvgUrl('edit.svg'))
      .addSvgIcon('mw-employees', this.trustSvgUrl('employees.svg'))
      .addSvgIcon('mw-exit', this.trustSvgUrl('exit.svg'))
      .addSvgIcon('mw-facebook', this.trustSvgUrl('facebook.svg'))
      .addSvgIcon('mw-faq', this.trustSvgUrl('faq.svg'))
      .addSvgIcon('mw-help', this.trustSvgUrl('help.svg'))
      .addSvgIcon('mw-history', this.trustSvgUrl('history.svg'))
      .addSvgIcon('mw-linkedin', this.trustSvgUrl('linkedin.svg'))
      .addSvgIcon('mw-app-icon', this.trustSvgUrl('mw-app-icon.svg'))
      .addSvgIcon('mw-logo-big', this.trustSvgUrl('mw-logo-big.svg'))
      .addSvgIcon('mw-logo-sml', this.trustSvgUrl('mw-logo-sml.svg'))
      .addSvgIcon('mw-my-apps', this.trustSvgUrl('my-apps.svg'))
      .addSvgIcon('mw-my-banq', this.trustSvgUrl('my-banq.svg'))
      .addSvgIcon('mw-my-benefits', this.trustSvgUrl('my-benefits.svg'))
      .addSvgIcon('mw-my-wealth', this.trustSvgUrl('my-wealth.svg'))
      .addSvgIcon('mw-off', this.trustSvgUrl('off.svg'))
      .addSvgIcon('mw-on', this.trustSvgUrl('on.svg'))
      .addSvgIcon('mw-pay-me', this.trustSvgUrl('pay-me.svg'))
      .addSvgIcon('mw-resend', this.trustSvgUrl('resend.svg'))
      .addSvgIcon('mw-chevron-light', this.trustSvgUrl('chevron-light.svg'))
      .addSvgIcon('mw-import', this.trustSvgUrl('import.svg'))
      .addSvgIcon('mw-twitter', this.trustSvgUrl('twitter.svg'))
      .addSvgIcon('mw-cursor', this.trustSvgUrl('cursor.svg'))
      .addSvgIcon('mw-phone-iphone', this.trustSvgUrl('phone_iphone.svg'))
      .addSvgIcon('mw-pin', this.trustSvgUrl('pin.svg'))
      .addSvgIcon('mw-sector', this.trustSvgUrl('sector.svg'))
      .addSvgIcon('mw-calculator', this.trustSvgUrl('calculator.svg'))
      .addSvgIcon('mw-calendar-closed', this.trustSvgUrl('calendar-closed.svg'))
      .addSvgIcon('mw-percent', this.trustSvgUrl('percent.svg'))
      .addSvgIcon('mw-reset-arrow', this.trustSvgUrl('reset-arrow.svg'))
      .addSvgIcon('mw-plus', this.trustSvgUrl('plus.svg'))
      .addSvgIcon('mw-calendar', this.trustSvgUrl('calendar.svg'))
      .addSvgIcon('mw-transaction-success', this.trustSvgUrl('check.svg'))
      .addSvgIcon('mw-transaction-fail', this.trustSvgUrl('error.svg'))
      .addSvgIcon('mw-transaction-pending', this.trustSvgUrl('pending.svg'))
      .addSvgIcon('mw-key', this.trustSvgUrl('key.svg'));
  }

  private trustSvgUrl(fileName: string) {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(`./../assets/icons/${fileName}`);
  }
}
