import { loginFailure, loginSuccess } from '@storeModule/auth/auth.actions';
import { isTokenExpired, isUserAuthenticated, selectToken } from '@storeModule/auth/auth.selectors';
import { combineLatest, of } from 'rxjs';
import { flatMap, take } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@ngrx/store";
export class AuthGuard {
    constructor(router, store) {
        this.router = router;
        this.store = store;
    }
    canActivate(next, state) {
        return combineLatest(this.store.select(isUserAuthenticated).pipe(take(1)), this.store.select(isTokenExpired).pipe(take(1)), this.store.select(selectToken).pipe(take(1))).pipe(take(1), flatMap(([userAuthenticated, tokenExpired, token]) => {
            const userLogged = userAuthenticated && !tokenExpired && token !== null;
            const userShouldBeLoggedIn = !userAuthenticated && !tokenExpired && token !== null;
            if (userLogged) {
                return of(true);
            }
            else if (userShouldBeLoggedIn) {
                this.store.dispatch(loginSuccess({ token, redirect: false }));
                return this.store.select(isUserAuthenticated)
                    .pipe(take(1));
            }
            else if (tokenExpired && token !== null) {
                this.store.dispatch(loginFailure());
                return of(false);
                // this.store.dispatch(refreshToken());
                // return this.store.select(selectToken)
                //   .pipe(
                //     filter(selectedToken => selectedToken !== token),
                //     map(selectedToken => selectedToken !== null)
                //   );
            }
            else {
                this.store.dispatch(loginFailure());
                return of(false);
            }
        }));
    }
}
AuthGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthGuard_Factory() { return new AuthGuard(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.Store)); }, token: AuthGuard, providedIn: "root" });
