import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Withdrawal } from '@app/shared/classes/withdrawal';
import { ICreateWithdrawalsRequest } from '@app/shared/models/api/employees/create-withdrawal';
import { IGetEmployeeResponse } from '@app/shared/models/api/employees/get-employee';
import { IGetFeesResponse } from '@app/shared/models/api/employees/get-fees';
import { IGetFundsResponse } from '@app/shared/models/api/employees/get-funds';
import { GetWithdrawalsListResponse } from '@app/shared/models/api/employees/get-withdrawals-list';
import { environment } from '@environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable()
export class EmployeesApiService {
  readonly baseUrl = `${environment.apiBaseUrl}/client`;

  constructor(private httpClient: HttpClient) {
  }

  getEmployee(): Observable<IGetEmployeeResponse> {
    return this.httpClient.get<IGetEmployeeResponse>(`${this.baseUrl}/v1/employee`);

  }

  getFees(withdrawalAmount: number): Observable<IGetFeesResponse> {
    return this.httpClient.get<IGetFeesResponse>(`${this.baseUrl}/v1/employee/calculateFee`, {
      params: {
        withdrawalAmount: withdrawalAmount + ''
      }
    });

  }

  getFunds(): Observable<IGetFundsResponse> {
    return this.httpClient.get<IGetFundsResponse>(`${this.baseUrl}/v1/employee/funds`);

  }

  createWithdrawal(request: ICreateWithdrawalsRequest): Observable<Withdrawal> {
    return this.httpClient.post<Withdrawal>(`${this.baseUrl}/v1/employee/withdrawals`, request)
      .pipe(
        map(response => new Withdrawal(response))
      );
  }

  getWithdrawals(): Observable<GetWithdrawalsListResponse> {
    return this.httpClient.get<GetWithdrawalsListResponse>(`${this.baseUrl}/v1/employee/withdrawals`)
      .pipe(
        map(response => response.map(w => new Withdrawal(w)))
      );
  }
}

