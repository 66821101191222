import { IGetEmployeeResponse } from '@app/shared/models/api/employees/get-employee';
import { IProfileResponse } from '@app/shared/models/api/profile/profile-response';
import { Action, createReducer, on } from '@ngrx/store';
import { IUserState } from '@storeModule/user/user-state.interface';
import { removeUser, saveUser } from '@storeModule/user/user.actions';
import { userState } from '@storeModule/user/user.state';

export function reducer(state: IUserState | undefined, action: Action) {
  return userReducer(state, action);
}

const userReducer = createReducer(
  userState,
  on(saveUser, (state: IUserState, props: { user: IGetEmployeeResponse | IProfileResponse }) => {
    return {...state, user: {...state.user, ...props.user}, loaded: true};

  }),
  on(removeUser, (state: IUserState) => {
    return {...state, user: {firstName: null, lastName: null, email: null}, loaded: false};
  })
);
