import { createAction } from '@ngrx/store';

export const minify = createAction('[Aside] Minify aside');
export const maximize = createAction('[Aside] Maximize aside');
export const toggleState = createAction('[Aside] Toggle aside state');

export const activateSmallScreenView = createAction('[Aside] Activate small screen view');
export const deactivateSmallScreenView = createAction('[Aside] Deactivate small screen view');

export const activateMobileScreenView = createAction('[Aside] Activate mobile screen view');
export const deactivateMobileScreenView = createAction('[Aside] Deactivate mobile screen view');
