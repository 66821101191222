import { createSelector } from '@ngrx/store';
import { IAsideState } from '@storeModule/aside/aside-state.interface';

import { IStoreState } from '@storeModule/store-state';

export const selectAsideState = (state: IStoreState) => state.aside;

export const isAsideMobile = createSelector(
  selectAsideState,
  (state: IAsideState) => state.mobileScreenView
);

export const isAsideMinified = createSelector(
  selectAsideState,
  (state: IAsideState) => {
    if (state.smallScreenView.active) {
      return state.smallScreenView.minified;

    } else if (state.mobileScreenView.active) {
      return false;

    } else {
      return state.minified;
    }
  }
);
