import { IHistoryOverviewData } from '@app/shared/models/history-overview-data';
import { IHistoryStateData } from '@app/shared/models/history-state-data';
import { IHistoryTotalSalaryAdvance } from '@app/shared/models/history-total-salary-advance';
import { Action, createReducer, on } from '@ngrx/store';
import { IHistoryState } from '@storeModule/history/history-state.interface';
import { getHistory, saveHistory } from '@storeModule/history/history.actions';
import { historyState } from '@storeModule/history/history.state';

export function reducer(state: IHistoryState | undefined, action: Action) {
  return historyReducer(state, action);
}

const historyReducer = createReducer(
  historyState,
  on(getHistory, (state: IHistoryState) => ({...state, loaded: false})),
  on(saveHistory, (state: IHistoryState, props: {
    history: Array<IHistoryStateData>,
    overview: Array<IHistoryOverviewData>,
    totalSalaryAdvance: Array<IHistoryTotalSalaryAdvance>
  }) => {
    const {
      history,
      overview,
      totalSalaryAdvance,
    } = props;

    return {...state, history, overview, totalSalaryAdvance, loaded: true};
  })
);
