import { Injectable } from '@angular/core';
import { EmployeesApiService } from '@app/core/api/employees/employees-api.service';
import { Withdrawal } from '@app/shared/classes/withdrawal';
import { HistoryPreparingService } from '@app/shared/services/history-preparing/history-preparing.service.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { getHistory, saveHistory } from '@storeModule/history/history.actions';
import { IStoreState } from '@storeModule/store-state';
import { concatMap, map } from 'rxjs/operators';

@Injectable()
export class HistoryEffects {
  getHistory$ = createEffect(() => this.actions$
    .pipe(
      ofType(getHistory),
      concatMap(() => this.employeesApiService.getWithdrawals()
        .pipe(
          map((withdrawals: Array<Withdrawal>) => saveHistory(this.historyPreparingService.prepareHistory(withdrawals)))
        ))
    ));

  constructor(
    private store: Store<IStoreState>,
    private actions$: Actions,
    private employeesApiService: EmployeesApiService,
    private historyPreparingService: HistoryPreparingService
  ) {
  }
}
