import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthApiService } from '@app/core/api/auth/auth-api.service';
import { authConfig } from '@app/shared/services/auth/auth.config';
import { Store } from '@ngrx/store';
import { selectToken } from '@storeModule/auth/auth.selectors';
import { IStoreState } from '@storeModule/store-state';
import { Observable, throwError } from 'rxjs';
import { flatMap, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private router: Router,
    private authApi: AuthApiService,
    private store: Store<IStoreState>) {
  }

  initLoginFlow(): void {
    location.href = `${authConfig.loginUrl}?returnUrl=${authConfig.redirectUrl}`;

  }

  initLogoutFlow(): void {
    location.href = `${authConfig.logoutUrl}?returnUrl=${authConfig.postLogoutRedirectUrl}`;

  }

  refreshToken(): Observable<string> {
    return this.store.select(selectToken)
      .pipe(
        take(1),
        flatMap(token => {
          if (token !== null) {
            return this.authApi.refreshToken(token);

          } else {
            throwError('No Token Available');
          }
        })
      );
  }
}

