import { WithdrawalStatus } from '@app/shared/enums/withdrawal-status.enum';
import { addSmallNumbers } from '@app/shared/methods/numbers/numbers';

export class Withdrawal {
  id = '';
  created = 0;
  availableFunds = 0;
  withdrawalAmount = 0;
  fee = 0;
  status: WithdrawalStatus = WithdrawalStatus.Pending;
  total = 0;
  statusMessage?: string;

  constructor(withdrawalConfiguration?: IWithdrawalConfiguration) {
    if (withdrawalConfiguration) {
      this.id = withdrawalConfiguration.hasOwnProperty('id') ? withdrawalConfiguration.id : this.id;
      this.created = withdrawalConfiguration.hasOwnProperty('created') ? withdrawalConfiguration.created : this.created;
      this.availableFunds = withdrawalConfiguration.hasOwnProperty('availableFunds') ? withdrawalConfiguration.availableFunds : this.availableFunds;
      this.withdrawalAmount = withdrawalConfiguration.hasOwnProperty('withdrawalAmount') ? withdrawalConfiguration.withdrawalAmount : this.withdrawalAmount;
      this.fee = withdrawalConfiguration.hasOwnProperty('fee') ? withdrawalConfiguration.fee : this.fee;
      this.status = withdrawalConfiguration.hasOwnProperty('status') ? withdrawalConfiguration.status : this.status;
      this.statusMessage = withdrawalConfiguration.hasOwnProperty('statusMessage') ? withdrawalConfiguration.statusMessage : this.statusMessage;
      this.total = addSmallNumbers(this.withdrawalAmount, this.fee);
    }
  }
}

export interface IWithdrawalConfiguration {
  id?: string;
  created?: number;
  availableFunds?: number;
  withdrawalAmount?: number;
  fee?: number;
  status?: WithdrawalStatus;
  statusMessage?: string;
}
