import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CkeyValidationService } from '@app/shared/services/ckey-validation/ckey-validation.service';
import { CryptService } from '@app/shared/services/crypt/crypt.service';
import { CoreModule } from '@coreModule/core.module';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CoreModule
  ],
  providers: [
    CryptService,
    CkeyValidationService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
