import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';

import { loginFailure, loginSuccess } from '@storeModule/auth/auth.actions';
import { isTokenExpired, isUserAuthenticated, selectToken } from '@storeModule/auth/auth.selectors';
import { IStoreState } from '@storeModule/store-state';
import { combineLatest, Observable, of } from 'rxjs';
import { flatMap, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private store: Store<IStoreState>) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return combineLatest(
      this.store.select(isUserAuthenticated).pipe(take(1)),
      this.store.select(isTokenExpired).pipe(take(1)),
      this.store.select(selectToken).pipe(take(1))
    ).pipe(
      take(1),
      flatMap(([userAuthenticated, tokenExpired, token]: [boolean, boolean, string]) => {
        const userLogged = userAuthenticated && !tokenExpired && token !== null;
        const userShouldBeLoggedIn = !userAuthenticated && !tokenExpired && token !== null;

        if (userLogged) {
          return of(true);

        } else if (userShouldBeLoggedIn) {
          this.store.dispatch(loginSuccess({token, redirect: false}));
          return this.store.select(isUserAuthenticated)
            .pipe(take(1));

        } else if (tokenExpired && token !== null) {
          this.store.dispatch(loginFailure());
          return of(false);

          // this.store.dispatch(refreshToken());
          // return this.store.select(selectToken)
          //   .pipe(
          //     filter(selectedToken => selectedToken !== token),
          //     map(selectedToken => selectedToken !== null)
          //   );

        } else {
          this.store.dispatch(loginFailure());
          return of(false);
        }
      })
    );
  }
}
