import { Action, createReducer, on } from '@ngrx/store';
import { IAsideState } from '@storeModule/aside/aside-state.interface';
import {
  activateMobileScreenView,
  activateSmallScreenView,
  deactivateMobileScreenView,
  deactivateSmallScreenView,
  maximize,
  minify,
  toggleState
} from '@storeModule/aside/aside.actions';
import { asideState } from '@storeModule/aside/aside.state';

export function reducer(state: IAsideState | undefined, action: Action) {
  return asideReducer(state, action);
}

const asideReducer = createReducer(
  asideState,
  on(minify, (state: IAsideState) => {
    return {
      ...state,
      minified: !state.smallScreenView.active && !state.mobileScreenView.active ? true : state.minified,
      smallScreenView: {
        ...state.smallScreenView,
        minified: state.smallScreenView.active ? true : state.smallScreenView.minified
      },
      mobileScreenView: {
        ...state.mobileScreenView,
        minified: state.mobileScreenView.active ? true : state.mobileScreenView.minified
      }
    };
  }),
  on(maximize, (state: IAsideState) => {
    return {
      ...state,
      minified: !state.smallScreenView.active && !state.mobileScreenView.active ? false : state.minified,
      smallScreenView: {
        ...state.smallScreenView,
        minified: state.smallScreenView.active ? false : state.smallScreenView.minified
      },
      mobileScreenView: {
        ...state.mobileScreenView,
        minified: state.mobileScreenView.active ? false : state.mobileScreenView.minified
      }
    };
  }),
  on(toggleState, (state: IAsideState) => {
    return {
      ...state,
      minified: !state.smallScreenView.active && !state.mobileScreenView.active ? !state.minified : state.minified,
      smallScreenView: {
        ...state.smallScreenView,
        minified: state.smallScreenView.active ? !state.smallScreenView.minified : state.smallScreenView.minified
      },
      mobileScreenView: {
        ...state.mobileScreenView,
        minified: state.mobileScreenView.active ? !state.mobileScreenView.minified : state.mobileScreenView.minified
      }
    };
  }),
  on(activateSmallScreenView, (state: IAsideState) => {
    return {
      ...state,
      smallScreenView: {
        ...state.smallScreenView,
        active: true,
        minified: true
      }
    };
  }),
  on(deactivateSmallScreenView, (state: IAsideState) => {
    return {
      ...state,
      smallScreenView: {
        ...state.smallScreenView,
        active: false,
        minified: true
      }
    };
  }),
  on(activateMobileScreenView, (state: IAsideState) => {
    return {
      ...state,
      mobileScreenView: {
        ...state.mobileScreenView,
        active: true,
        minified: true
      }
    };
  }),
  on(deactivateMobileScreenView, (state: IAsideState) => {
    return {
      ...state,
      mobileScreenView: {
        ...state.mobileScreenView,
        active: false,
        minified: true
      }
    };
  })
);


