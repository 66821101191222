import { Action, createReducer, on } from '@ngrx/store';
import { IAuthState } from '@storeModule/auth/auth-state.interface';
import { loginSuccess, logout, refreshToken, refreshTokenFailure, refreshTokenSuccess } from '@storeModule/auth/auth.actions';
import { authState } from '@storeModule/auth/auth.state';

export function reducer(state: IAuthState | undefined, action: Action) {
  return authReducer(state, action);
}

const authReducer = createReducer(
  authState,
  on(loginSuccess, (state: IAuthState, props: { token: string, redirect?: boolean }) => ({
    ...state,
    token: props.token,
    authenticated: true
  })),
  on(logout, ((state: IAuthState) => ({...state, token: null, refreshInProgress: false, authenticated: false}))),
  on(refreshToken, ((state: IAuthState) => ({...state, refreshInProgress: true}))),
  on(refreshTokenSuccess, (state: IAuthState, props: { token: string }) => ({...state, refreshInProgress: false, token: props.token})),
  on(refreshTokenFailure, (state: IAuthState) => ({...state, refreshInProgress: false}))
);
