import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule, MatDialogModule } from '@angular/material';
import { DialogErrorInterceptedComponent } from '@app/shared/dialogs/dialog-error-intercepted/dialog-error-intercepted.component';
import { DateService } from '@app/shared/services/date/date.service';
import { NavigationListenerService } from '@app/shared/services/navigation-listener/navigation-listener.service';
import { AgGridModule } from 'ag-grid-angular';


@NgModule({
  declarations: [
    DialogErrorInterceptedComponent
  ],
  entryComponents: [
    DialogErrorInterceptedComponent
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    AgGridModule
  ],
  exports: [
    DialogErrorInterceptedComponent
  ],
  providers: [NavigationListenerService, DatePipe, DateService]
})
export class SharedCoreModule {
}
