export class HttpError {
  readonly message: string;
  readonly errorDescription: string;
  readonly errorKey: string;

  constructor(message, errorDescription, errorKey?) {
    this.message = message;
    this.errorDescription = errorDescription;
    this.errorKey = errorKey;
  }
}
