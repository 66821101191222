import { addSmallNumbers, subtractionOfSmallNumbers } from '@app/shared/methods/numbers/numbers';
import { createReducer, on } from '@ngrx/store';
import { calculateFee, calculateFundsLeftAfterTransfer, calculateTotal, decreaseWithdraw, increaseWithdraw, saveAvailableFunds, saveFee, setWithdraw } from '@storeModule/payment/payment.actions';
import { paymentState } from '@storeModule/payment/payment.state';
export function reducer(state, action) {
    return paymentReducer(state, action);
}
const ɵ0 = (state) => {
    if (!(state.withdraw.value + state.withdraw.step > state.funds.value)) {
        return Object.assign({}, state, { withdraw: Object.assign({}, state.withdraw, { value: state.withdraw.value + state.withdraw.step }) });
    }
    return state;
}, ɵ1 = (state) => {
    if (state.withdraw.value - state.withdraw.step >= state.withdraw.min) {
        return Object.assign({}, state, { withdraw: Object.assign({}, state.withdraw, { value: state.withdraw.value - state.withdraw.step }) });
    }
    return state;
}, ɵ2 = (state, props) => (Object.assign({}, state, { withdraw: Object.assign({}, state.withdraw, { value: props.withdraw }) })), ɵ3 = (state) => {
    return Object.assign({}, state, { status: Object.assign({}, state.status, { feeLoading: true }) });
}, ɵ4 = (state, props) => {
    return Object.assign({}, state, { withdraw: Object.assign({}, state.withdraw, { fee: props.fee }), status: Object.assign({}, state.status, { feeLoading: false }) });
}, ɵ5 = (state, props) => (Object.assign({}, state, { funds: Object.assign({}, state.funds, { value: props.funds }), status: Object.assign({}, state.status, { withdrawalDisabled: props.funds < state.withdraw.min }) })), ɵ6 = (state) => {
    return Object.assign({}, state, { withdraw: Object.assign({}, state.withdraw, { total: addSmallNumbers(state.withdraw.value, state.withdraw.fee) }) });
}, ɵ7 = (state) => {
    return Object.assign({}, state, { funds: Object.assign({}, state.funds, { leftAfterWithdraw: subtractionOfSmallNumbers(state.funds.value, state.withdraw.total) }) });
};
const paymentReducer = createReducer(paymentState, on(increaseWithdraw, ɵ0), on(decreaseWithdraw, ɵ1), on(setWithdraw, ɵ2), on(calculateFee, ɵ3), on(saveFee, ɵ4), on(saveAvailableFunds, ɵ5), on(calculateTotal, ɵ6), on(calculateFundsLeftAfterTransfer, ɵ7));
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7 };
