import { Injectable } from '@angular/core';
import { CryptService } from '@app/shared/services/crypt/crypt.service';

@Injectable()
export class CkeyValidationService {

  constructor(private cryptService: CryptService) {
  }

  isJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  isCKeyValid(company, CKey) {
    if (CKey !== null && typeof CKey === 'string') {
      const decryptedId = this.cryptService.decrypt(company.keyControl, this.cryptService.keyBase64ToKeyBytes(CKey));

      return (decryptedId === company.id);
    }
    return false;
  }
}
