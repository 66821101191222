import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AsideStoreModule } from '@storeModule/aside/aside-store.module';
import { AuthStoreModule } from '@storeModule/auth/auth-store.module';
import { HistoryStoreModule } from '@storeModule/history/history-store.module';
import { PaymentStoreModule } from '@storeModule/payment/payment-store.module';
import { ScreenStoreModule } from '@storeModule/screen/screen-store.module';
import { storeDevtools } from '@storeModule/store-devtools';

import { UserStoreModule } from '@storeModule/user/user-store.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    UserStoreModule,
    AuthStoreModule,
    AsideStoreModule,
    PaymentStoreModule,
    ScreenStoreModule,
    HistoryStoreModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    ...storeDevtools
  ]
})
export class StorageModule {
}
