import { ScreenResolution } from '@app/shared/services/screen-resolution-listener.service/screen-resolution.enum';
import { createSelector } from '@ngrx/store';
import { IStoreState } from '@storeModule/store-state';

export const selectResolution = (state: IStoreState) => state.screen;

export const selectCurrentResolution = createSelector(
  selectResolution,
  (state) => state.resolution
);

export const isScreenResolutionDefault = createSelector(
  selectCurrentResolution,
  (state) => state === ScreenResolution.Default
);
export const isScreenResolutionExtraLarge = createSelector(
  selectCurrentResolution,
  (state) => state === ScreenResolution.ExtraLarge
);
export const isScreenResolutionLarge = createSelector(
  selectCurrentResolution,
  (state) => state === ScreenResolution.Large
);
export const isScreenResolutionMedium = createSelector(
  selectCurrentResolution,
  (state) => state === ScreenResolution.Medium
);
export const isScreenResolutionSmall = createSelector(
  selectCurrentResolution,
  (state) => state === ScreenResolution.Small
);

