import { Injectable } from '@angular/core';
import { Withdrawal } from '@app/shared/classes/withdrawal';
import { addSmallNumbers } from '@app/shared/methods/numbers/numbers';
import { IHistoryOverviewData } from '@app/shared/models/history-overview-data';
import { IHistoryStateData } from '@app/shared/models/history-state-data';
import { IHistoryTotalSalaryAdvance } from '@app/shared/models/history-total-salary-advance';
import { DateService } from '@app/shared/services/date/date.service';

@Injectable()
export class HistoryPreparingService {
  constructor(private date: DateService) {
  }

  prepareHistory(withdrawals: Array<Withdrawal>):
    { history: Array<IHistoryStateData>, overview: Array<IHistoryOverviewData>, totalSalaryAdvance: Array<IHistoryTotalSalaryAdvance> } {
    withdrawals.sort((withdrawal1, withdrawal2) => withdrawal2.created - withdrawal1.created);

    const lastWithdrawal = withdrawals[withdrawals.length - 1];
    const lastWithdrawalDate = lastWithdrawal ? new Date(lastWithdrawal.created) : new Date();
    const {
      map,
      history,
      overview,
      totalSalaryAdvance
    } = this.generateDefaultMap(this.date.getMonthDiff(new Date(), new Date(lastWithdrawalDate)));

    const withdrawalsLength = withdrawals.length;
    for (let i = 0; i < withdrawalsLength; i++) {
      const withdrawal = withdrawals[i];
      const withdrawalMonthRange = this.date.getMonthRange(new Date(withdrawal.created));

      if (map.get(+withdrawalMonthRange.from)) {
        this.setRecord(map, withdrawalMonthRange, withdrawal);

      } else {
        this.createRecord(map, withdrawalMonthRange, withdrawal, history, overview, totalSalaryAdvance);
      }
    }

    return {history, overview, totalSalaryAdvance};
  }

  getMonthDetailsTotal(monthDetails: IHistoryStateData) {
    return new Withdrawal(monthDetails.data.reduce((acc, withdrawal) => {
      acc.withdrawalAmount += withdrawal.withdrawalAmount;
      acc.fee = addSmallNumbers(acc.fee, withdrawal.fee);

      return acc;
    }, {created: +monthDetails.date.from, withdrawalAmount: 0, fee: 0}));
  }

  private createRecord(
    map: Map<number, {
      history: IHistoryStateData;
      overview: IHistoryOverviewData;
      totalSalaryAdvance: IHistoryTotalSalaryAdvance
    }>,
    withdrawalMonthRange: { from: Date; to: Date },
    withdrawal: Withdrawal,
    history: Array<IHistoryStateData>,
    overview: Array<IHistoryOverviewData>,
    totalSalaryAdvance: Array<IHistoryTotalSalaryAdvance>) {

    map.set(+withdrawalMonthRange.from, {
      history: {
        date: withdrawalMonthRange,
        data: [withdrawal]
      },
      overview: {
        date: withdrawalMonthRange,
        total: withdrawal.withdrawalAmount
      },
      totalSalaryAdvance: {
        date: withdrawalMonthRange,
        total: withdrawal.withdrawalAmount
      }
    });

    history.push(map.get(+withdrawalMonthRange.from).history);
    overview.push(map.get(+withdrawalMonthRange.from).overview);
    totalSalaryAdvance.push(map.get(+withdrawalMonthRange.from).totalSalaryAdvance);
  }

  private setRecord(
    map: Map<number, {
      history: IHistoryStateData;
      overview: IHistoryOverviewData;
      totalSalaryAdvance: IHistoryTotalSalaryAdvance
    }>,
    withdrawalMonthRange: { from: Date; to: Date },
    withdrawal: Withdrawal) {

    map.get(+withdrawalMonthRange.from).history.data.push(withdrawal);
    map.get(+withdrawalMonthRange.from).overview.total += withdrawal.withdrawalAmount;
    map.get(+withdrawalMonthRange.from).totalSalaryAdvance.total += withdrawal.withdrawalAmount;

  }

  private generateDefaultMap(monthCount) {
    const now = new Date();
    const history: Array<IHistoryStateData> = [];
    const overview: Array<IHistoryOverviewData> = [];
    const totalSalaryAdvance: Array<IHistoryTotalSalaryAdvance> = [];
    const map = new Map<number, {
      history: IHistoryStateData,
      overview: IHistoryOverviewData,
      totalSalaryAdvance: IHistoryTotalSalaryAdvance
    }>();

    for (let i = 0; i < monthCount; i++) {
      const date = new Date();
      date.setMonth(now.getMonth() - i);

      const monthRange = this.date.getMonthRange(date);

      map.set(+monthRange.from, {
        history: {
          date: monthRange,
          data: []
        },
        overview: {
          date: monthRange,
          total: 0
        },
        totalSalaryAdvance: {
          date: monthRange,
          total: 0
        }
      });

      history.push(map.get(+monthRange.from).history);
      overview.push(map.get(+monthRange.from).overview);
      totalSalaryAdvance.push(map.get(+monthRange.from).totalSalaryAdvance);
    }

    return {
      map, history, overview, totalSalaryAdvance
    };
  }
}
