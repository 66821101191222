import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AuthApiService } from '@app/core/api/auth/auth-api.service';
import { EmployeesApiService } from '@app/core/api/employees/employees-api.service';
import { ErrorInterceptor } from '@app/core/api/interceptors/error.interceptor';
import { TokenInterceptor } from '@app/core/api/interceptors/token.interceptor';
import { MockApiModule } from '@app/core/api/mock-api/mock-api.module';
import { environment } from '@environment';

const mockApiModule = environment.mockApi ? [MockApiModule] : [];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ...mockApiModule
  ],
  providers: [
    AuthApiService,
    EmployeesApiService,
    {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
  ]
})
export class ApiModule {
}
