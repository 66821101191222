import { createReducer, on } from '@ngrx/store';
import { activateMobileScreenView, activateSmallScreenView, deactivateMobileScreenView, deactivateSmallScreenView, maximize, minify, toggleState } from '@storeModule/aside/aside.actions';
import { asideState } from '@storeModule/aside/aside.state';
export function reducer(state, action) {
    return asideReducer(state, action);
}
const ɵ0 = (state) => {
    return Object.assign({}, state, { minified: !state.smallScreenView.active && !state.mobileScreenView.active ? true : state.minified, smallScreenView: Object.assign({}, state.smallScreenView, { minified: state.smallScreenView.active ? true : state.smallScreenView.minified }), mobileScreenView: Object.assign({}, state.mobileScreenView, { minified: state.mobileScreenView.active ? true : state.mobileScreenView.minified }) });
}, ɵ1 = (state) => {
    return Object.assign({}, state, { minified: !state.smallScreenView.active && !state.mobileScreenView.active ? false : state.minified, smallScreenView: Object.assign({}, state.smallScreenView, { minified: state.smallScreenView.active ? false : state.smallScreenView.minified }), mobileScreenView: Object.assign({}, state.mobileScreenView, { minified: state.mobileScreenView.active ? false : state.mobileScreenView.minified }) });
}, ɵ2 = (state) => {
    return Object.assign({}, state, { minified: !state.smallScreenView.active && !state.mobileScreenView.active ? !state.minified : state.minified, smallScreenView: Object.assign({}, state.smallScreenView, { minified: state.smallScreenView.active ? !state.smallScreenView.minified : state.smallScreenView.minified }), mobileScreenView: Object.assign({}, state.mobileScreenView, { minified: state.mobileScreenView.active ? !state.mobileScreenView.minified : state.mobileScreenView.minified }) });
}, ɵ3 = (state) => {
    return Object.assign({}, state, { smallScreenView: Object.assign({}, state.smallScreenView, { active: true, minified: true }) });
}, ɵ4 = (state) => {
    return Object.assign({}, state, { smallScreenView: Object.assign({}, state.smallScreenView, { active: false, minified: true }) });
}, ɵ5 = (state) => {
    return Object.assign({}, state, { mobileScreenView: Object.assign({}, state.mobileScreenView, { active: true, minified: true }) });
}, ɵ6 = (state) => {
    return Object.assign({}, state, { mobileScreenView: Object.assign({}, state.mobileScreenView, { active: false, minified: true }) });
};
const asideReducer = createReducer(asideState, on(minify, ɵ0), on(maximize, ɵ1), on(toggleState, ɵ2), on(activateSmallScreenView, ɵ3), on(deactivateSmallScreenView, ɵ4), on(activateMobileScreenView, ɵ5), on(deactivateMobileScreenView, ɵ6));
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6 };
