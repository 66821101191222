import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpStatusCode } from '@app/core/api/http-status-codes';
import { Store } from '@ngrx/store';
import { refreshToken } from '@storeModule/auth/auth.actions';
import { isTokenRefreshInProgress, selectToken } from '@storeModule/auth/auth.selectors';
import { IStoreState } from '@storeModule/store-state';
import { Observable, throwError } from 'rxjs';
import { catchError, filter, flatMap, map, take } from 'rxjs/operators';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private store: Store<IStoreState>) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.store.select(selectToken)
      .pipe(
        take(1),
        map(token => {
          if (token !== null) {
            return {
              req: this.addToken(req, token),
              token
            };
          }

          return {req, token};
        }),
        flatMap(cfg => next.handle(cfg.req)
          .pipe(
            catchError(error => {
              if (error instanceof HttpErrorResponse && error.status === HttpStatusCode.Unauthorized) {
                if (req.url.includes('refreshToken')) {
                  return throwError(error);

                }
                return throwError(error); // this.handle401Error(req, next, cfg.token);
              }

              return throwError(error);
            })
          ))
      );
  }

  protected addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }

  protected handle401Error(request: HttpRequest<any>, next: HttpHandler, oldToken: string): Observable<HttpEvent<any>> {
    return this.store.select(isTokenRefreshInProgress)
      .pipe(
        take(1),
        flatMap(tokenRefreshInProgress => {
          if (!tokenRefreshInProgress) {
            this.store.dispatch(refreshToken());
          }

          return this.store.select(selectToken)
            .pipe(
              filter(refreshedToken => refreshedToken !== oldToken),
              take(1),
              flatMap(refreshedToken => {
                if (refreshedToken !== null) {
                  return next.handle(this.addToken(request, refreshedToken));

                } else {
                  return throwError('Unauthorized');

                }
              })
            );
        })
      );
  }
}
