import { Injectable } from '@angular/core';
import { algorithm, ivSize, keySize, salt } from '@app/shared/services/crypt/crypt.config';
import * as forge from 'node-forge';

@Injectable()
export class CryptService {
  constructor() {
  }

  base64ToBase64url(base64string: string): string {
    return base64string.split('=')[0].replace(/\+/g, '-').replace(/\//g, '_');
  }

  hashData(data: string): string {
    const md = forge.md.sha256.create();
    md.update(data + salt);

    const output = md.digest().getBytes();
    return forge.util.encode64(output);
  }

  generateKey(): string {
    return forge.random.getBytesSync(keySize);
  }

  keyBase64ToKeyBytes(key: string): string {
    return forge.util.decode64(key);
  }

  keyBytesToKeyBase64(key: string): string {
    return forge.util.encode64(key);
  }

  encrypt(data: string, key: string): string {
    const cipher = forge.cipher.createCipher(algorithm, key);
    const iv = forge.random.getBytesSync(ivSize);

    cipher.start({iv});
    cipher.update(forge.util.createBuffer(data));
    cipher.finish();

    return forge.util.encode64(iv + cipher.output.getBytes());
  }

  decrypt(data: string, key: string): string {
    try {
      data = forge.util.decode64(data);

      const iv = data.slice(0, ivSize);
      const decodedData = data.slice(ivSize);

      const decipher = forge.cipher.createDecipher(algorithm, key);

      decipher.start({iv});
      decipher.update(forge.util.createBuffer(decodedData));
      decipher.finish(); // check 'result' for true/false

      return decipher.output.toString();
    } catch (e) {
      return '';
    }
  }
}
