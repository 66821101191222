import { JwtHelperService } from '@auth0/angular-jwt';
import { createSelector } from '@ngrx/store';
import { IAuthState } from '@storeModule/auth/auth-state.interface';
import { IStoreState } from '@storeModule/store-state';

export const selectAuthState = (state: IStoreState) => state.auth;

export const selectToken = createSelector(
  selectAuthState,
  (auth: IAuthState) => auth.token
);

export const isTokenRefreshInProgress = createSelector(
  selectAuthState,
  (auth: IAuthState) => auth.refreshInProgress
);

export const isUserAuthenticated = createSelector(
  selectAuthState,
  (auth: IAuthState) => auth.authenticated
);

export const isTokenExpired = createSelector(
  selectToken,
  (token: string) => new JwtHelperService().isTokenExpired(token)
);
