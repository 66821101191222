import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { HttpError } from '@app/shared/classes/http-error';

@Component({
  selector: 'mw-dialog-error-intercepted',
  templateUrl: './dialog-error-intercepted.component.html',
  styleUrls: ['./dialog-error-intercepted.component.scss']
})
export class DialogErrorInterceptedComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogErrorInterceptedComponent>,
    @Inject(MAT_DIALOG_DATA) public data: HttpError) {

  }

  ngOnInit() {
  }

}
