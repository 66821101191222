import { IAsideState } from '@storeModule/aside/aside-state.interface';

export const asideState: IAsideState = {
  minified: false,
  smallScreenView: {
    active: false,
    minified: false
  },
  mobileScreenView: {
    active: false,
    minified: false
  }
};
