import { createReducer, on } from '@ngrx/store';
import { getHistory, saveHistory } from '@storeModule/history/history.actions';
import { historyState } from '@storeModule/history/history.state';
export function reducer(state, action) {
    return historyReducer(state, action);
}
const ɵ0 = (state) => (Object.assign({}, state, { loaded: false })), ɵ1 = (state, props) => {
    const { history, overview, totalSalaryAdvance, } = props;
    return Object.assign({}, state, { history, overview, totalSalaryAdvance, loaded: true });
};
const historyReducer = createReducer(historyState, on(getHistory, ɵ0), on(saveHistory, ɵ1));
export { ɵ0, ɵ1 };
