import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';

import { reducer } from '@storeModule/aside/aside.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('aside', reducer)
  ],
  exports: [StoreModule]
})
export class AsideStoreModule {
}
