import { createAction, props } from '@ngrx/store';

export const login = createAction('[Auth] Login');
export const loginSuccess = createAction('[Auth] Login success', props<{ token: string, redirect?: boolean }>());
export const loginFailure = createAction('[Auth] Login failure');

export const logout = createAction('[Auth] Logout');

export const refreshToken = createAction('[Auth] Refresh token');
export const refreshTokenSuccess = createAction('[Auth] Refresh token success', props<{ token: string }>());
export const refreshTokenFailure = createAction('[Auth] Refresh token failure');
