import { Injectable } from '@angular/core';
import { EmployeesApiService } from '@app/core/api/employees/employees-api.service';
import { IGetEmployeeResponse } from '@app/shared/models/api/employees/get-employee';
import { IUserService } from '@app/shared/services/user/user-service';
import { Observable } from 'rxjs';

@Injectable()
export class UserService implements IUserService {

  constructor(private employeeApi: EmployeesApiService) {
  }

  getUser(): Observable<IGetEmployeeResponse> {
    return this.employeeApi.getEmployee();
  }
}
