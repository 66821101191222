import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HistoryPreparingService } from '@app/shared/services/history-preparing/history-preparing.service.service';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { HistoryEffects } from '@storeModule/history/history.effects';
import { reducer } from '@storeModule/history/history.reducer';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('history', reducer),
    EffectsModule.forFeature([HistoryEffects])
  ],
  providers: [HistoryPreparingService]
})
export class HistoryStoreModule {
}
