import { IPaymentState } from '@storeModule/payment/payment-state.interface';

const minValueOption = 20;
const middleValueOption = 50;
const maxValueOption = 100;

export const paymentState: IPaymentState = {
  funds: {
    value: null,
    leftAfterWithdraw: null
  },
  withdraw: {
    value: 10,
    min: 10,
    step: 10,
    fee: null,
    total: 0
  },
  options: [minValueOption, middleValueOption, maxValueOption],
  status: {
    withdrawalDisabled: true,
    feeLoading: true
  }
};
