import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { reducer } from '@storeModule/payment/papyment.reducer';
import { PaymentEffects } from '@storeModule/payment/payment.effects';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('payment', reducer),
    EffectsModule.forFeature([PaymentEffects])
  ]
})
export class PaymentStoreModule {
}
