import { IUserState } from '@storeModule/user/user-state.interface';

export const userState: IUserState = {
  loaded: false,
  user: {
    firstName: null,
    lastName: null,
    email: null
  }

};
