const coefficient = 10000;

export function addSmallNumbers(...numbers: number[]): number {
  if (numbers.length === 1) {
    return numbers[0];
  }

  if (numbers.length === 0) {
    return 0;
  }

  return numbers.reduce((acc, nextNumber) => {
    acc = acc + (nextNumber * coefficient);

    return acc;
  }, 0) / coefficient;
}


export function subtractionOfSmallNumbers(...numbers: number[]): number {
  if (numbers.length === 1) {
    return numbers[0];
  }

  if (numbers.length === 0) {
    return 0;
  }

  return numbers.reduce((acc, nextNumber) => {
    acc = acc - (nextNumber * coefficient);

    return acc;
  }, numbers.shift() * coefficient) / coefficient;
}

// ((0.3 *1000) + (0.3 * 1000)) / 1000
