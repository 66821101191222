import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ScreenEffects } from '@storeModule/screen/screen.effects';
import { reducer } from '@storeModule/screen/screen.reducer';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('screen', reducer),
    EffectsModule.forFeature([ScreenEffects])
  ],
  exports: [StoreModule]
})
export class ScreenStoreModule {
}
