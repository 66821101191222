import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HttpStatusCode } from '@app/core/api/http-status-codes';
import { HttpError } from '@app/shared/classes/http-error';
import { DialogErrorInterceptedComponent } from '@app/shared/dialogs/dialog-error-intercepted/dialog-error-intercepted.component';
import { Store } from '@ngrx/store';
import { logout } from '@storeModule/auth/auth.actions';
import { IStoreState } from '@storeModule/store-state';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private dialog: MatDialog, private store: Store<IStoreState>) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req)
      .pipe(catchError((err: HttpErrorResponse) => {
        const status: HttpStatusCode = err.status;
        const message: string = err.error ? err.error.message : err.statusText;
        const description: string = err.error ? err.error.errorDescription : err.message;

        switch (status) {
          case HttpStatusCode.InternalServerError:
          case HttpStatusCode.BadRequest:
            this.dialog.open(DialogErrorInterceptedComponent, {
              data: new HttpError(message, description)
            });

            break;

          case HttpStatusCode.Unauthorized:
            this.store.dispatch(logout());
        }

        return throwError(err);
      }));
  }

}

