import { Routes } from '@angular/router';
import { AuthGuard } from '@app/shared/guards/auth/auth.guard';
const ɵ0 = () => import("./main/main.module.ngfactory").then(module => module.MainModuleNgFactory), ɵ1 = () => import("./welcome/welcome.module.ngfactory").then(module => module.WelcomeModuleNgFactory), ɵ2 = () => import("./oauth/oauth.module.ngfactory").then(module => module.OauthModuleNgFactory);
const routes = [
    {
        path: '',
        loadChildren: ɵ0,
        canActivate: [AuthGuard]
    },
    {
        path: 'welcome',
        loadChildren: ɵ1
    },
    {
        path: 'oauth-login-result',
        loadChildren: ɵ2
    },
    {
        path: '**',
        redirectTo: ''
    }
];
export class AppRoutingModule {
}
export { ɵ0, ɵ1, ɵ2 };
