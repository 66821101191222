import { Injectable } from '@angular/core';
import { ScreenResolutionListenerService } from '@app/shared/services/screen-resolution-listener.service/screen-resolution-listener.service';
import { ScreenResolution } from '@app/shared/services/screen-resolution-listener.service/screen-resolution.enum';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { IAsideState } from '@storeModule/aside/aside-state.interface';
import {
  activateMobileScreenView,
  activateSmallScreenView,
  deactivateMobileScreenView,
  deactivateSmallScreenView
} from '@storeModule/aside/aside.actions';
import { selectAsideState } from '@storeModule/aside/aside.selectors';
import { saveScreenResolution, startScreenResizeListener, stopScreenResizeListener } from '@storeModule/screen/screen.actions';
import { IStoreState } from '@storeModule/store-state';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';

@Injectable()
export class ScreenEffects {
  changeResolution$ = createEffect(() => this.actions$
    .pipe(
      ofType(saveScreenResolution),
      map(action => action.screenResolution),
      withLatestFrom(this.store.select(selectAsideState)),
      switchMap(([screenResolution, asideState]: [ScreenResolution, IAsideState]) => {
        const actions: Array<Action> = [];
        const largeScreen = screenResolution > ScreenResolution.Large;
        const smallScreen = screenResolution <= ScreenResolution.Large && screenResolution >= ScreenResolution.Medium;
        const mobileScreen = screenResolution < ScreenResolution.Medium;

        if (largeScreen) {
          if (asideState.smallScreenView.active) {
            actions.push(deactivateSmallScreenView());
          }
          if (asideState.mobileScreenView.active) {
            actions.push(deactivateMobileScreenView());
          }
        }

        if (smallScreen) {
          if (!asideState.smallScreenView.active) {
            actions.push(activateSmallScreenView());
          }

          if (asideState.mobileScreenView.active) {
            actions.push(deactivateMobileScreenView());
          }
        }
        if (mobileScreen) {
          if (!asideState.mobileScreenView.active) {
            actions.push(activateMobileScreenView());
          }
          if (asideState.smallScreenView.active) {
            actions.push(deactivateSmallScreenView());
          }
        }

        return actions;
      })
    ));

  startListener$ = createEffect(() => this.actions$
    .pipe(
      ofType(startScreenResizeListener),
      map(() => {
        this.screenResolutionListenerService.initListener();
        return saveScreenResolution({screenResolution: this.screenResolutionListenerService.getCurrentScreenResolution()});
      })
    ));

  stopListener$ = createEffect(() => this.actions$
    .pipe(
      ofType(stopScreenResizeListener),
      map(() => {
        this.screenResolutionListenerService.removeListener();
        return saveScreenResolution({screenResolution: ScreenResolution.Default});
      })
    ));

  constructor(
    private store: Store<IStoreState>,
    private screenResolutionListenerService: ScreenResolutionListenerService,
    private actions$: Actions
  ) {
  }
}
