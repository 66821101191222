import { IHistoryOverviewData } from '@app/shared/models/history-overview-data';
import { IHistoryStateData } from '@app/shared/models/history-state-data';
import { IHistoryTotalSalaryAdvance } from '@app/shared/models/history-total-salary-advance';
import { createAction, props } from '@ngrx/store';

export const getHistory = createAction('[History] Get history list');
export const saveHistory = createAction('[History] Save history list',
  props<{
    history: Array<IHistoryStateData>,
    overview: Array<IHistoryOverviewData>,
    totalSalaryAdvance: Array<IHistoryTotalSalaryAdvance>
  }>());

