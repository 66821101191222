/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dialog-error-intercepted.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i4 from "@angular/material/button";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "./dialog-error-intercepted.component";
var styles_DialogErrorInterceptedComponent = [i0.styles];
var RenderType_DialogErrorInterceptedComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DialogErrorInterceptedComponent, data: {} });
export { RenderType_DialogErrorInterceptedComponent as RenderType_DialogErrorInterceptedComponent };
export function View_DialogErrorInterceptedComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h1", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(1, 81920, null, 0, i2.MatDialogTitle, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], null, null), (_l()(), i1.ɵted(-1, null, ["Oops, Something went wrong"])), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["style", "line-height: 2rem; margin-bottom: 1rem"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 5, "mat-dialog-actions", [["class", "mat-dialog-actions"]], null, null, null, null, null)), i1.ɵdid(9, 16384, null, 0, i2.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(10, 0, null, null, 3, "button", [["class", "button mat-button-base__big"], ["mat-dialog-close", ""], ["mat-flat-button", ""]], [[1, "aria-label", 0], [1, "type", 0], [1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 11).dialogRef.close(i1.ɵnov(_v, 11).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(11, 606208, null, 0, i2.MatDialogClose, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], { dialogResult: [0, "dialogResult"] }, null), i1.ɵdid(12, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, ["OK"]))], function (_ck, _v) { _ck(_v, 1, 0); var currVal_7 = ""; _ck(_v, 11, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.data.message; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.data.errorDescription; _ck(_v, 7, 0, currVal_2); var currVal_3 = (i1.ɵnov(_v, 11).ariaLabel || null); var currVal_4 = i1.ɵnov(_v, 11).type; var currVal_5 = (i1.ɵnov(_v, 12).disabled || null); var currVal_6 = (i1.ɵnov(_v, 12)._animationMode === "NoopAnimations"); _ck(_v, 10, 0, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_DialogErrorInterceptedComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mw-dialog-error-intercepted", [], null, null, null, View_DialogErrorInterceptedComponent_0, RenderType_DialogErrorInterceptedComponent)), i1.ɵdid(1, 114688, null, 0, i7.DialogErrorInterceptedComponent, [i2.MatDialogRef, i2.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DialogErrorInterceptedComponentNgFactory = i1.ɵccf("mw-dialog-error-intercepted", i7.DialogErrorInterceptedComponent, View_DialogErrorInterceptedComponent_Host_0, {}, {}, []);
export { DialogErrorInterceptedComponentNgFactory as DialogErrorInterceptedComponentNgFactory };
