import { createAction, props } from '@ngrx/store';

export const getAvailableFunds = createAction('[Payment] Get available funds to withdraw');
export const saveAvailableFunds = createAction('[Payment] Save available funds to withdraw', props<{ funds: number }>());

export const increaseWithdraw = createAction('[Payment] increase withdraw');
export const decreaseWithdraw = createAction('[Payment] decrease withdraw');
export const setWithdraw = createAction('[Payment] set withdraw', props<{ withdraw: number }>());

export const calculateFee = createAction('[Payment] Calculate Fee');
export const saveFee = createAction('[Payment] Save fee', props<{ fee: number }>());

export const calculateTotal = createAction('[Payment] Calculate total deducted from salary');
export const calculateFundsLeftAfterTransfer = createAction('[Payment] Calculate total funds that left after transfer');

export const createWithdraw = createAction('[Payment] Create withdraw');
