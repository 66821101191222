import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ApiModule } from '@app/core/api/api.module';
import { MaterialCoreModule } from '@app/core/material/material-core.module';
import { ScreenResolutionListenerService } from '@app/shared/services/screen-resolution-listener.service/screen-resolution-listener.service';
import { UserService } from '@app/shared/services/user/user.service';
import { SharedCoreModule } from '@app/shared/shared-core.module';
import { StoreModule } from '@ngrx/store';

import { StorageModule } from '@storeModule/storage.module';
import { ToastrModule } from 'ngx-toastr';


@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    ApiModule,
    StorageModule,
    SharedCoreModule,
    MaterialCoreModule.forRoot(),
    ToastrModule.forRoot({
      progressBar: true,
      positionClass: 'toast-top-center'
    })
  ],
  exports: [HttpClientModule, StoreModule, MaterialCoreModule],
  providers: [
    ScreenResolutionListenerService,
    UserService
  ]
})
export class CoreModule {
}
