import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Observable } from 'rxjs';

@Injectable()
export class AuthApiService {
  protected baseUrl = `${environment.apiBaseUrl}`;

  constructor(private http: HttpClient) {
  }

  refreshToken(token: string): Observable<string> {
    return this.http.get<string>(`${this.baseUrl}/login/refreshToken`, {
      params: {token}
    });
  }
}
