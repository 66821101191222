import { Injectable } from '@angular/core';
import { ScreenResolution } from '@app/shared/services/screen-resolution-listener.service/screen-resolution.enum';
import { Store } from '@ngrx/store';
import { saveScreenResolution } from '@storeModule/screen/screen.actions';
import { selectCurrentResolution } from '@storeModule/screen/screen.selectors';
import { IStoreState } from '@storeModule/store-state';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime, filter, map, withLatestFrom } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ScreenResolutionListenerService {
  protected windowResize$: Subscription;

  constructor(private store: Store<IStoreState>) {
  }

  initListener(): void {
    if (!(this.windowResize$ instanceof Subscription)) {
      this.windowResize$ = fromEvent(window, 'resize')
        .pipe(
          debounceTime(100),
          map(() => this.getCurrentScreenResolution()),
          withLatestFrom(this.store.select(selectCurrentResolution)),
          filter(([newResolution, currentResolution]) => newResolution !== currentResolution),
        )
        .subscribe(([newResolution]: [ScreenResolution, ScreenResolution]) => {
          this.store.dispatch(saveScreenResolution({screenResolution: newResolution}));

        });
    }
  }

  removeListener(): void {
    this.windowResize$.unsubscribe();
    this.windowResize$ = null;
  }

  getCurrentScreenResolution(): ScreenResolution {
    if (window.innerWidth <= ScreenResolution.Small) {
      return ScreenResolution.Small;

    } else if (window.innerWidth <= ScreenResolution.Medium) {
      return ScreenResolution.Medium;

    } else if (window.innerWidth <= ScreenResolution.Large) {
      return ScreenResolution.Large;

    } else if (window.innerWidth <= ScreenResolution.ExtraLarge) {
      return ScreenResolution.ExtraLarge;

    } else {
      return ScreenResolution.Default;
    }
  }
}
