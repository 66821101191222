import { createReducer, on } from '@ngrx/store';
import { removeUser, saveUser } from '@storeModule/user/user.actions';
import { userState } from '@storeModule/user/user.state';
export function reducer(state, action) {
    return userReducer(state, action);
}
const ɵ0 = (state, props) => {
    return Object.assign({}, state, { user: Object.assign({}, state.user, props.user), loaded: true });
}, ɵ1 = (state) => {
    return Object.assign({}, state, { user: { firstName: null, lastName: null, email: null }, loaded: false });
};
const userReducer = createReducer(userState, on(saveUser, ɵ0), on(removeUser, ɵ1));
export { ɵ0, ɵ1 };
