import { authConfig } from '@app/shared/services/auth/auth.config';
import { selectToken } from '@storeModule/auth/auth.selectors';
import { throwError } from 'rxjs';
import { flatMap, take } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../../../core/api/auth/auth-api.service";
import * as i3 from "@ngrx/store";
export class AuthService {
    constructor(router, authApi, store) {
        this.router = router;
        this.authApi = authApi;
        this.store = store;
    }
    initLoginFlow() {
        location.href = `${authConfig.loginUrl}?returnUrl=${authConfig.redirectUrl}`;
    }
    initLogoutFlow() {
        location.href = `${authConfig.logoutUrl}?returnUrl=${authConfig.postLogoutRedirectUrl}`;
    }
    refreshToken() {
        return this.store.select(selectToken)
            .pipe(take(1), flatMap(token => {
            if (token !== null) {
                return this.authApi.refreshToken(token);
            }
            else {
                throwError('No Token Available');
            }
        }));
    }
}
AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.AuthApiService), i0.ɵɵinject(i3.Store)); }, token: AuthService, providedIn: "root" });
