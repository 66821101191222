import { GestureConfig, HammerManager } from '@angular/material';


export class HammerConfig extends GestureConfig {
  buildHammer(element: HTMLElement) {
    const mc = super.buildHammer(element) as HammerManager;

    mc.set({touchAction: 'pan-y'});

    return mc;
  }
}
