import { ScreenResolution } from '@app/shared/services/screen-resolution-listener.service/screen-resolution.enum';
import { saveScreenResolution } from '@storeModule/screen/screen.actions';
import { selectCurrentResolution } from '@storeModule/screen/screen.selectors';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime, filter, map, withLatestFrom } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class ScreenResolutionListenerService {
    constructor(store) {
        this.store = store;
    }
    initListener() {
        if (!(this.windowResize$ instanceof Subscription)) {
            this.windowResize$ = fromEvent(window, 'resize')
                .pipe(debounceTime(100), map(() => this.getCurrentScreenResolution()), withLatestFrom(this.store.select(selectCurrentResolution)), filter(([newResolution, currentResolution]) => newResolution !== currentResolution))
                .subscribe(([newResolution]) => {
                this.store.dispatch(saveScreenResolution({ screenResolution: newResolution }));
            });
        }
    }
    removeListener() {
        this.windowResize$.unsubscribe();
        this.windowResize$ = null;
    }
    getCurrentScreenResolution() {
        if (window.innerWidth <= ScreenResolution.Small) {
            return ScreenResolution.Small;
        }
        else if (window.innerWidth <= ScreenResolution.Medium) {
            return ScreenResolution.Medium;
        }
        else if (window.innerWidth <= ScreenResolution.Large) {
            return ScreenResolution.Large;
        }
        else if (window.innerWidth <= ScreenResolution.ExtraLarge) {
            return ScreenResolution.ExtraLarge;
        }
        else {
            return ScreenResolution.Default;
        }
    }
}
ScreenResolutionListenerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ScreenResolutionListenerService_Factory() { return new ScreenResolutionListenerService(i0.ɵɵinject(i1.Store)); }, token: ScreenResolutionListenerService, providedIn: "root" });
